
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import moment from "moment";
import { Modal } from "bootstrap";
import {
  educationOptions,
  employmentOptions,
} from "../../../../data/FASLookups";
import { getBirthdate, getGender, defaultProfile } from "../../../utils/ProfileUtils";
import { profileDetailsValidator } from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  name: "update-personal",
  props: ["theProfile", "genders", "states", "races", "pCities"],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const addSpouseModal = ref(null);
    const closeModal = () => Modal.getInstance(addSpouseModal.value)?.hide();
    return {
      addSpouseModal,
      closeModal,
    };
  },
  data() {
    const store = useStore();

    const profile = {...defaultProfile};

    const spouse = {
      name: "",
      nric: "",
      email: "",
      phone: "",
      employment: "",
    };

    return {
      store,
      profile,
      profileDetailsValidator: profileDetailsValidator,
      employments: employmentOptions,
      educations: educationOptions,
      spouse,
      showSpouseDetails: false,
    };
  },
  async mounted() {
    let tempProfile = this.theProfile;
    if (!tempProfile.user_raw_profile) {
      tempProfile.user_raw_profile = this.profile.user_raw_profile;
    }
    this.profile = tempProfile;
    if (this.profile.gender == null || this.profile.gender == "") {
      this.profile.gender = getGender(this.profile.nric);
    }
    if (this.profile.birthdate == null || this.profile.birthdate == "") {
      this.profile.birthdate = getBirthdate(this.profile.nric);
    }

    this.getCities(this.profile.user_raw_profile.raw.state);
  },
  methods: {
    moment: function () {
      return moment();
    },
    getHumanDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    getCities(state) {
      this.$emit("getCities", { state: state, type: "personal" });
    },
    getStateId(state_name) {
      for (let index of Object.keys(this.states)) {
        if (state_name == this.states[index].ParameterValue) {
          return this.states[index].id;
        }
      }
    },
    onStateChange() {
      this.getCities(this.profile.user_raw_profile.raw.state);
    },
    updateProfile() {
      this.profile.user_raw_profile.raw.name = this.profile.name;
      this.profile.user_raw_profile.raw.nric = this.profile.nric;
      this.profile.user_raw_profile.raw.email = this.profile.email;
      this.profile.user_raw_profile.raw.phone = this.profile.phone;
      this.profile.user_raw_profile.raw.gender = this.profile.gender;
      this.profile.user_raw_profile.raw.birthdate = this.profile.birthdate;

      this.$emit("onSaveWillPlanning", "Personal");
    },
  },
});
