
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";

import { maritalStatusOptions } from "../../../../data/FASLookups";
import { defaultSibling, getGender } from "../../../utils/ProfileUtils";
import { siblingValidator } from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  props: ["theSiblings", "genders", "states", "sCities"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    const siblings = [];

    return {
      sibling: { ...defaultSibling },
      siblings,
      maritalStatuses: maritalStatusOptions,
      schema: siblingValidator,
      action: "addSibling",
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    const addSiblingModal = ref(null);
    const closeModal = () => Modal.getInstance(addSiblingModal.value)?.hide();

    return {
      addSiblingModal,
      closeModal,
    };
  },
  methods: {
    init() {
      this.siblings = this.theSiblings;
    },
    onStateChange() {
      this.$emit("getCities", { state: this.sibling.state, type: "siblings" });
    },
    onShowAddSiblingModal() {
      this.action = "addSibling";
      this.sibling = { ...defaultSibling };
    },
    onUpdateSibling() {
      if (this.action == "addSibling") {
        let tempSibling = this.sibling;
        this.siblings.push(tempSibling);
      }
      (this.sibling = { ...defaultSibling }), this.closeModal();
    },
    onEditSibling(index) {
      this.action = "editSibling";
      this.sibling = this.siblings[index];
    },
    onRemoveSibling(index) {
      this.siblings.splice(index, 1);
    },
    onResetSiblings() {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this siblings details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          for (let index = 0; index < this.siblings.length; index++) {
            this.siblings.splice(index, this.siblings.length);
          }
          Swal.fire({
            title: "Reset!",
            text: "Siblings details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
    onSaveSiblings() {
      this.$emit("onSaveWillPlanning", "Siblings");
    },
  },
  watch: {
    "sibling.nric": function (val) {
      if (val.length == 12) {
        this.sibling.gender = getGender(val);
      }
    },
  },
  async mounted() {
    this.init();
  },
});
