
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defaultParent } from "../../../utils/ProfileUtils";
import { parentsValidator } from "../../../utils/FinancialPlanningValidatorUtils";
export default defineComponent({
  props: ["theProfile", "theParents", "states", "faCities", "moCities"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      parents: {
        father: { ...defaultParent },
        mother: { ...defaultParent },
      },
      schema: parentsValidator,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    return {};
  },
  methods: {
    init() {
      this.parents = this.theParents;
    },
    onStateFatherChange() {
      this.$emit("getCities", {
        state: this.parents.father.state,
        type: "father",
      });
    },
    onStateMotherChange() {
      this.$emit("getCities", {
        state: this.parents.mother.state,
        type: "mother",
      });
    },
    onResetParents() {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this parents details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.resetParents();
          Swal.fire({
            title: "Reset!",
            text: "Parents details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
    resetParents() {
      this.parents.father = { ...defaultParent };
      this.parents.mother = { ...defaultParent };
    },
    onSaveParentsInfo() {
      this.$emit("onSaveWillPlanning", "Parents");
    },
  },
  async mounted() {
    this.init();
  },
});
