
import { defineComponent, ref, Ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";

import {
  defaultExecutor,
  defaultExecutorWithEnable,
  defaultExecutors,
} from "../../../utils/ProfileUtils";
import { executorValidator } from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  props: ["theExecutors", "states", "f1Cities", "s2Cities"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    const executorsFormRef: Ref<HTMLFormElement | null> = ref(null);
    return {
      executors: { ...defaultExecutors },
      executorsFormRef,
      schema: executorValidator,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    return {};
  },
  methods: {
    init() {
      this.executors = this.theExecutors;
    },
    onStateFatherChange() {
      this.$emit("getCities", {
        state: this.executors.executor_1.state,
        type: "executor_1",
      });
    },
    onStateMotherChange() {
      this.$emit("getCities", {
        state: this.executors.executor_2.state,
        type: "executor_2",
      });
    },
    onExecutor2Change() {
      if (!this.executors.executor_2.is_enable) {
        this.executors = {
          ...this.executors,
          executor_2: { ...defaultExecutorWithEnable },
        };
      } else {
        this.executors.executor_2= { ...defaultExecutorWithEnable, is_enable: true }
      }
      this.$forceUpdate();
    },
    onResetExecutors() {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this executors details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.executors = { ...defaultExecutors };
          this.$emit("resetExecutors", "Executors");
          Swal.fire({
            title: "Reset!",
            text: "Executors details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
    onSaveExecutorsInfo() {
      this.$emit("onSaveWillPlanning", "Executors");
    },
  },
  async mounted() {
    this.init();
  },
});
