
import { defineComponent, ref } from "vue";
import ElCurrencyInput from "@/components/financial/CashFlowCurrencyInput.vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import { Delete, Edit, Plus } from "@element-plus/icons-vue";
import { currencyFormat } from "@/core/helpers/currencyFormatter";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default defineComponent({
  props: [
    "isMuslim",
    "theSpouses",
    "theChildren",
    "theSiblings",
    "theOthers",
    "theParents",
  ],
  components: {
    Form,
    Field,
  },
  data() {
    const distribution = {} as any;

    const spouses = this.theSpouses;
    const children = this.theChildren;
    const siblings = this.theSiblings;
    const others = this.theOthers;
    const parents = this.theParents;

    return {
      distribution,
      spouses,
      children,
      siblings,
      others,
      parents,
      totalPercentage: 0,
    };
  },
  setup() {
    // const { t, te, n } = useI18n();

    return {};
  },
  methods: {
    init() {
      this.calculateTotalPercentage();
    },
    calculateTotalPercentage() {
      const calculateArrayTotal = (arr) =>
        arr.reduce((sum, item) => sum + parseFloat(item.percentage || 0), 0);

      let totalPercentage = calculateArrayTotal(this.others);

      if (!this.isMuslim) {
        totalPercentage +=
          calculateArrayTotal(this.spouses) +
          calculateArrayTotal(this.children) +
          calculateArrayTotal(this.siblings) +
          parseFloat(this.parents.father.percentage ?? 0) +
          parseFloat(this.parents.mother.percentage ?? 0);
      }

      this.totalPercentage = totalPercentage;
    },
    resetBeneficiaryValue(beneficiary, index) {
      let target;

      if (beneficiary === "father" || beneficiary === "mother") {
        // Accessing properties directly for father and mother
        target = this.parents[beneficiary];
      } else if (
        ["spouses", "children", "siblings", "others"].includes(beneficiary)
      ) {
        // Accessing array elements for other beneficiaries
        target = this[beneficiary][index];
      }

      if (target && !target.receive_portion) {
        target.percentage = 0;
        target.special_bequest = "";
      }

      this.calculateTotalPercentage();
    },
    createDistributionArray(array) {
      return array.map((item) => ({
        name: item.organisation_name ?? item.name,
        nric: item.organisation_reg_no ?? item.nric,
        percentage: item.percentage,
        special_bequest: item.special_bequest,
        receive_portion: item.receive_portion,
      }));
    },

    resetBeneficiaryAttributes(beneficiaries) {
      beneficiaries.forEach((beneficiary) => {
        beneficiary.percentage = 0;
        beneficiary.special_bequest = "";
        beneficiary.receive_portion = false;
      });
    },

    calculateDistribution() {
      return new Promise<void>((resolve, reject) => {
        this.distribution = {} as any;

        if (this.isMuslim) {
          this.resetBeneficiaryAttributes([
            this.parents.father,
            this.parents.mother,
          ]);
          this.resetBeneficiaryAttributes([...this.spouses, ...this.children]);

          this.distribution = {
            others: this.createDistributionArray(this.others),
          };
        } else {
          this.distribution = {
            parents: {
              father: {
                name: this.parents.father.name,
                nric: this.parents.father.nric,
                percentage: this.parents.father.percentage,
                special_bequest: this.parents.father.special_bequest,
                receive_portion: this.parents.father.receive_portion,
              },
              mother: {
                name: this.parents.mother.name,
                nric: this.parents.mother.nric,
                percentage: this.parents.mother.percentage,
                special_bequest: this.parents.mother.special_bequest,
                receive_portion: this.parents.mother.receive_portion,
              },
            },
            spouses: this.createDistributionArray(this.spouses),
            children: this.createDistributionArray(this.children),
            siblings: this.createDistributionArray(this.siblings),
            others: this.createDistributionArray(this.others),
          };
        }
        resolve();
      });
    },

    async onSaveDistributionInfo() {
      Swal.fire({
        title: "Calculation in progress",
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await this.calculateDistribution();

      this.calculateTotalPercentage();

      setTimeout(() => {
        if (this.isMuslim) {
          this.$emit("onSaveWillPlanning", "Distribution", this.distribution);
        } else {
          if (this.totalPercentage !== 100) {
            Swal.fire({
              title: "Error!",
              text:
                "Please ensure that the total distribution is 100% under any circumstances.",
              icon: "error",
              confirmButtonText: "OK",
            });
            return;
          } else {
            Swal.close();
            this.$emit("onSaveWillPlanning", "Distribution", this.distribution);
          }
        }
      }, 1000);
    },
  },
  async mounted() {
    this.init();
  },
});
