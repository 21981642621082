
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { useForm } from "vee-validate";

interface HirePurchase {
  loanAmount: string;
  loanPeriod: number;
  interestRate: number;
}

export default defineComponent({
  props: ["theResiduaryEstate"],
  components: {
    // ErrorMessage,
    // Field,
    Form,
    // ElCurrencyInput,
  },
  data() {
    const route = useRoute();
    const submitButton1 = ref<HTMLElement | null>(null);
    const profile = {} as any;
    const checkAll = false;
    const isIndeterminate = false;
    const benefeciaries = [
      "Spouse(s)",
      "Children",
      "Benefeciaries",
      "Heirs-at-Law (Family by Blood)",
    ];

    const residuary_estate = {
      details: [],
    };

    return {
      profile,
      residuary_estate,
      checkAll,
      isIndeterminate,
      benefeciaries,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    return {};
  },
  methods: {
    init() {
      this.residuary_estate = this.theResiduaryEstate;
    },
    onSaveResiduaryEstateInfo() {
      this.$emit("onSaveWillPlanning", "Residuary Estate");
    },
    handleCheckedCitiesChange(value: string[]) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.benefeciaries.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.benefeciaries.length;
    },
    handleCheckAllChange(val: boolean) {
      this.residuary_estate.details = val ? this.benefeciaries : [];
      this.isIndeterminate = false;
    },
  },
  async mounted() {
    this.init();
  },
});
