
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { specialRequestValidator } from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  props: ["theSpecialRequest"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    const special_request = {
      is_enable: false,
      details: "",
    };

    return {
      special_request,
      schema: specialRequestValidator,
    };
  },
  methods: {
    init() {
      this.special_request = this.theSpecialRequest;
    },
    onSaveTrusteesInfo() {
      this.$emit("onSaveWillPlanning", "Special Request");
    },
  },
  async mounted() {
    this.init();
  },
});
