
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { useForm } from "vee-validate";

interface HirePurchase {
  loanAmount: string;
  loanPeriod: number;
  interestRate: number;
}

export default defineComponent({
  props: ["thePersonalBelongings"],
  components: {
    // ErrorMessage,
    Field,
    Form,
    // ElCurrencyInput,
  },
  data() {
    const route = useRoute();
    const submitButton1 = ref<HTMLElement | null>(null);
    const profile = {} as any;

    const personal_belongings = {
      details: "",
    };

    return {
      profile,
      personal_belongings,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    return {};
  },
  methods: {
    init() {
      this.personal_belongings = this.thePersonalBelongings;
    },
    onSavePersonalBelongingsInfo() {
      this.$emit("onSaveWillPlanning", "Personal Belongings");
    },
  },
  async mounted() {
    this.init();
  },
});
