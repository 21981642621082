
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  defaultTrustee,
  default2ndTrustee,
  defaultTrustees,
} from "../../../utils/ProfileUtils";
import { trusteeOptions } from "../../../../data/FASLookups";
import { trusteeValidator } from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  props: ["theTrustees", "states", "t1Cities", "t2Cities"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      trustees: defaultTrustees,
      trusteesList: trusteeOptions,
      schema: trusteeValidator,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    return {};
  },
  methods: {
    init() {
      this.trustees = this.theTrustees;
      if( this.trustees.trustee_1?.state) {
        this.onStateTrustee1Change(this.trustees.trustee_1.state);
      }

      if( this.trustees.trustee_2?.state) {
        this.onStateTrustee1Change(this.trustees.trustee_2.state);
      }
    },
    onStateTrustee1Change(state) {
      this.$emit("getCities", {
        state: state,
        type: "trustee_1",
      });
    },
    onStateTrustee2Change(state) {
      this.$emit("getCities", {
        state: state,
        type: "trustee_2",
      });
    },
    onTrustee2Change() {
      if (!this.trustees.trustee_2.is_enable) {
        this.trustees.trustee_2 = default2ndTrustee;
      }
    },
    onResetTrustees() {
      this.trustees = defaultTrustees;
    },
    onSaveTrusteesInfo() {
      this.$emit("onSaveWillPlanning", "Trustees");
    },
  },
  async mounted() {
    this.init();
  },
});
