
import { defineComponent, ref } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import { Delete, Edit, Plus } from "@element-plus/icons-vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getAge, getGender, defaultChild } from "../../../utils/ProfileUtils";
import { childValidator } from "../../../utils/FinancialPlanningValidatorUtils";
export default defineComponent({
  props: ["theProfile", "thechildren", "genders"],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const children = [];
    return {
      child: {...defaultChild},
      children,
      action: "addChild",
      schema: childValidator,
    };
  },
  async mounted() {
    this.init();
  },
  setup() {
    const addChildModal = ref(null);
    const closeModal = () => Modal.getInstance(addChildModal.value)?.hide();

    return {
      Plus,
      Edit,
      Delete,
      addChildModal,
      closeModal,
    };
  },
  methods: {
    init() {
      this.children = this.thechildren;
    },
    onShowAddChildModal() {
      this.action = "addChild";
      this.child = {...defaultChild};
    },
    onUpdateChildren() {
      if (this.action == "addChild") {
        let tempChild = this.child;
        this.children.push(tempChild);
      }
      this.child = {...defaultChild};

      this.closeModal();
    },
    onEditChild(index) {
      this.action = "editChild";
      this.child = this.children[index];
    },
    onRemoveChild(index) {
      this.children.splice(index, 1);
    },
    onResetChildren() {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this children details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          for (let index = 0; index < this.children.length; index++) {
            this.children.splice(index, this.children.length);
          }
          Swal.fire({
            title: "Reset!",
            text: "Children details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
    onSaveChildren() {
      this.$emit("onSaveWillPlanning", "Children");
    },
    async mounted() {
      this.init();
    },
  },
  watch: {
    "child.is_under_18": function (val) {
      if (!val) {
        this.child.guardian_name = "";
      }
    },
    "child.is_oku": function (val) {
      if (!val) {
        this.child.guardian_name = "";
      }
    },
    "child.nric": function (val) {
      if (val.length == 12) {
        this.child.gender = getGender(val);
        this.child.is_under_18 = getAge(val) < 18 ? true : false;
      }
    },
  },
});
