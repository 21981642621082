import * as Yup from "yup";

// Reusable Validators
const requiredString = (label: string) =>
  Yup.string()
    .required()
    .label(label)
    .typeError(`Please enter a valid ${label}.`);

const requiredEmail = (label: string) =>
  Yup.string()
    .email()
    .required()
    .label(label)
    .typeError(`Please enter a valid ${label}.`);

const requiredNRIC = (label: string) =>
  Yup.string()
    .required()
    .test("malaysianID", `Please enter a valid ${label}.`, function (value) {
      const { path, createError } = this;
      const icRegex = /^\d{12}$/; // Regex for 12 digits IC number
      const passportRegex = /^[A-Za-z0-9]{7,11}$/; // Regex for Malaysian Passport number

      // Check if the value matches either IC or Passport format
      const isValid = icRegex.test(value) || passportRegex.test(value);
      return value == null || isValid || createError({ path });
    })
    .label(label)
    .typeError(`Please enter a valid ${label}.`);

const requiredNumberWithMinMaxDigits = (
  label: string,
  minDigits: number,
  maxDigits: number
) =>
  Yup.number()
    .required()
    .test(
      "minDigits",
      `Please enter a valid ${label}.`,
      (number) =>
        String(number).length >= minDigits && String(number).length <= maxDigits
    )
    .typeError(`Please enter a valid ${label}.`);

const requiredNumber = (label: string) =>
  Yup.number().required().label(`${label}`).typeError(`Please enter a valid ${label}.`);

export const profileDetailsValidator = Yup.object({
  profile: Yup.object({
    name: requiredString("Name"),
    nric: Yup.string().required().label("NRIC"),
    email: requiredEmail("Email"),
    phone: requiredNumberWithMinMaxDigits("Phone No", 8, 11),
    gender: Yup.string().required().label("Gender"),
    birthdate: Yup.string()
      .required()
      .label("Birthdate")
      .typeError("Please select date."),
    user_raw_profile: Yup.object({
      raw: Yup.object({
        race: Yup.string().required().label("Race"),
        employment: Yup.string().required().label("Employment"),
        education: Yup.string().required().label("Education"),
        alt_phone: requiredNumberWithMinMaxDigits("Phone", 8, 11),
        address1: Yup.string().required().label("Address Line 1"),
        address2: Yup.string().required().label("Address Line 2"),
        state: Yup.string().required().label("State"),
        city: Yup.string().required().label("City"),
        postcode: Yup.number()
          .required()
          .label("Postcode")
          .typeError("Please enter a valid Postcode eg: 12345"),
      }),
    }),
  }),
});



export const spouseValidator = Yup.object({
  spouse: Yup.object({
    name: requiredString("Name"),
    nric: requiredNRIC("NRIC"),
    email: requiredEmail("Email"),
    phone: requiredNumberWithMinMaxDigits("Phone No", 8, 11),
    employment: Yup.string()
      .required()
      .label("Employment Status")
      .typeError("Please select Employment Status"),
    residential_status: Yup.string()
      .required()
      .label("Residential Status")
      .typeError("Please select Residential Status"),
    education: Yup.string()
      .required()
      .label("Education")
      .typeError("Please select Education"),
    race: Yup.string().required().label("Race").typeError("Please select Race"),
    birthdate: Yup.string()
      .required()
      .label("Birthdate")
      .typeError("Please select date"),
  }),
});

export const spouseValidatorForWillPlanning = Yup.object({
  spouse: Yup.object({
    name: requiredString("Name"),
    nric: requiredNRIC("NRIC"),
    email: requiredEmail("Email"),
    phone: requiredNumberWithMinMaxDigits("Phone No", 8, 11),
    employment: Yup.string()
      .required()
      .label("Employment Status")
      .typeError("Please select Employment Status"),
    race: Yup.string().required().label("Race").typeError("Please select Race"),
    birthdate: Yup.string()
      .required()
      .label("Birthdate")
      .typeError("Please select date"),
  }),
});

export const childValidator = Yup.object({
  child: Yup.object({
    name: Yup.string().required().label("Name").typeError("Please enter Name"),
    nric: requiredNRIC("NRIC"),
    gender: Yup.string().required().label("Gender"),
  }),
});

export const parentsValidator = Yup.object({
  parents: Yup.object({
    father: Yup.object({
      name: Yup.string().required().label("Name").typeError("Name is required"),
      nric: requiredNRIC("NRIC"),
      phone: requiredNumberWithMinMaxDigits("Phone No", 8, 11),
    }),
    mother: Yup.object({
      name: Yup.string().required().label("Name").typeError("Name is required"),
      nric: requiredNRIC("NRIC"),
      phone: requiredNumberWithMinMaxDigits("Phone No", 8, 11),
    }),
  }),
});

export const siblingValidator = Yup.object({
  sibling: Yup.object({
    name: Yup.string().required().label("Name").typeError("Please enter Name"),
    nric: requiredNRIC("NRIC"),
    phone: requiredNumberWithMinMaxDigits("Phone No", 8, 11),
    gender: Yup.string()
      .required()
      .label("Gender")
      .typeError("Gender is required"),
    marital_status: Yup.string()
      .required()
      .label("Marital Status")
      .typeError("Marital Status is required"),
    spouse_name: Yup.string().when("marital_status", {
      is: "Married",
      then: Yup.string()
        .required()
        .label("Spouse Name")
        .typeError("Spouse Name is required"),
    }),
  }),
});

export const otherValidator = Yup.object({
  other: Yup.object({
    is_individual: Yup.boolean().label("Type"),
    name: Yup.string().when("is_individual", {
      is: true,
      then: Yup.string().required().label("Name").typeError("Name is required"),
    }),
    nric: Yup.string().when("is_individual", {
      is: true,
      then: requiredNRIC("NRIC"),
    }),
    organisation_name: Yup.string().when("is_individual", {
      is: false,
      then: Yup.string()
        .required()
        .label("Organisation Name")
        .typeError("Organisation Name is required"),
    }),
    organisation_reg_no: Yup.string().when("is_individual", {
      is: false,
      then: Yup.string()
        .required("Invalid Organisation Reg No is required")
        .test("malaysianID", "Invalid Organisation Reg No", function (value) {
          const { path, createError } = this;
          const icRegex = /^\d{12}$/; // Regex for 12 digits IC number
          const passportRegex = /^[A-Za-z0-9]{6,11}$/; // Regex for Malaysian Passport number

          // Check if the value matches either IC or Passport format
          const isValid = icRegex.test(value) || passportRegex.test(value);
          return value == null || isValid || createError({ path });
        }),
    }),
    phone: requiredNumberWithMinMaxDigits("Phone No", 8, 11),
  }),
});

export const executorValidator = Yup.object({
  executors: Yup.object({
    executor_1: Yup.object({
      name: Yup.string().required().label("Name").typeError("Name is required"),
      nric: requiredNRIC("NRIC"),
      phone: requiredNumberWithMinMaxDigits("Phone No", 8, 11),
    }),
    executor_2: Yup.object({
      is_enable: Yup.boolean().label("Enable"),
      name: Yup.string().when("is_enable", {
        is: true,
        then: Yup.string()
          .required()
          .label("Name")
          .typeError("Name is is_enable"),
      }),
      nric: Yup.string().when("is_enable", {
        is: true,
        then: requiredNRIC("NRIC"),
      }),
      phone: Yup.number().when("is_enable", {
        is: true,
        then: requiredNumberWithMinMaxDigits("Phone No", 8, 11),
      }),
    }),
  }),
});

export const trusteeValidator = Yup.object({
  trustees: Yup.object({
    trustee_1: Yup.object({
      name: Yup.string().required().label("Name").typeError("Name is required"),
      phone: requiredNumberWithMinMaxDigits("Phone No", 8, 11),
    }),
    trustee_2: Yup.object({
      is_enable: Yup.boolean().label("Enable"),
      name: Yup.string().when("is_enable", {
        is: true,
        then: Yup.string()
          .required()
          .label("Name")
          .typeError("Name is is_enable"),
      }),
      phone: Yup.number().when("is_enable", {
        is: true,
        then: requiredNumberWithMinMaxDigits("Phone No", 8, 11),
      }),
    }),
  }),
});

export const specialRequestValidator = Yup.object({
  special_request: Yup.object({
    is_enable: Yup.boolean().label("Enable"),
    details: Yup.string().when("is_enable", {
      is: true,
      then: Yup.string()
        .required()
        .label("Special Request")
        .typeError("Special Request is required"),
    }),
  }),
});


//Risk Planning Validation

export const profileDetailsValidatorForRiskPlanning = Yup.object({
  risk: Yup.object({
    about_you: Yup.object({
      age: requiredNumberWithMinMaxDigits("Age", 1, 2),
      annual_income: requiredNumber("Annual Income"),
    }),
  }),
});

export const spouseValidatorForRiskPlanning = Yup.object({
  spouse: Yup.object({
    name: requiredString("Name"),
    age: requiredNumberWithMinMaxDigits("Age", 1, 2),
    retirement_age: requiredNumberWithMinMaxDigits("Retirement Age", 1, 2),
    annual_income: requiredNumber("Anual Income"),
  }),
});

export const OneTimeCostValidator = Yup.object({
  risk: Yup.object({
    one_time_cost: Yup.object({
      funeral: requiredNumber("Funeral and other Final Expenses"),
      outstanding_mortgage: requiredNumber("Outstanding Mortgage"),
      debt: requiredNumber("Debts or Obligations"),
      emergency_fund: requiredNumber("Emergency Fund"),
    }),
  }),
});
