
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";
import {
  employmentOptions,
  maritalStatusOptions,
} from "../../../../data/FASLookups";
import { getBirthdate } from "../../../utils/ProfileUtils";
import { spouseValidatorForWillPlanning } from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  props: ["theProfile", "isMuslim", "states", "spCities", "races"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    const spouse = {
      name: "",
      nric: "",
      email: "",
      phone: "",
      employment: "",
      address_1: "",
      address_2: "",
      address_3: "",
      state: "",
      city: "",
      postcode: "",
      birthdate: "",
      race: "",
      percentage: 0,
      receive_portion: false,
      entire_estate: false,
    };

    const profile = {
      id: null,
      user_raw_profile: {
        id: null,
        user_id: null,
        type: "profile",
        raw: {
          marital_status: "",
        },
      },
      user_raw_spouses: {
        id: null,
        user_id: null,
        type: "spouse",
        raw: [],
      },
    };

    const actions = "addSpouse";

    return {
      profile,
      maritalStatuses: maritalStatusOptions,
      spouse,
      addSpouseValidator: spouseValidatorForWillPlanning,
      employments: employmentOptions,
      actions,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    const addSpouseModal = ref(null);
    const closeModal = () => Modal.getInstance(addSpouseModal.value)?.hide();
    const showModal = () => Modal.getInstance(addSpouseModal.value)?.show();
    return {
      addSpouseModal,
      closeModal,
      showModal,
    };
  },
  methods: {
    init() {
      // console.log("init");
    },
    onShowAddSpouseModal() {
      this.actions = "addSpouse";
      this.spouse = {
        name: "",
        nric: "",
        email: "",
        phone: "",
        employment: "",
        address_1: "",
        address_2: "",
        address_3: "",
        state: "",
        city: "",
        postcode: "",
        birthdate: "",
        race: "",
        percentage: 0,
        receive_portion: false,
        entire_estate: false,
      };
    },
    onEditSpouse(index) {
      this.actions = "editSpouse";
      this.spouse = this.profile.user_raw_spouses.raw[index];
    },
    onUpdateSpouse() {
      if (this.actions == "addSpouse") {
        this.profile.user_raw_spouses.raw.push(this.spouse);
      }

      this.closeModal();
      return;
    },
    onStateChange() {
      this.$emit("getCities", {
        state: this.spouse.state,
        type: "spouse",
      });
    },
    onRemoveSpouse(index) {
      this.profile.user_raw_spouses.raw.splice(index, 1);
    },
    onResetSpouses() {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this spouse details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          for (
            let index = 0;
            index < this.profile.user_raw_spouses.raw.length;
            index++
          ) {
            this.profile.user_raw_spouses.raw.splice(
              index,
              this.profile.user_raw_spouses.raw.length
            );
          }
          Swal.fire({
            title: "Reset!",
            text: "Spouse details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
    onSaveSpouses() {
      if (
        this.profile.user_raw_profile.raw.marital_status == "Married" &&
        this.profile.user_raw_spouses.raw.length == 0
      ) {
        Swal.fire({
          title: "Error!",
          text: "Please add at least one spouse.",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      this.$emit("onSaveWillPlanning", "Spouses");
    },
  },
  watch: {
    "profile.user_raw_profile.raw.marital_status": function (
      newStatus,
      oldStatus
    ) {
      if (newStatus !== "Married") {
        this.profile.user_raw_spouses.raw = [];
      }
    },
    "spouse.nric": function (newNric, oldNric) {
      if (newNric.length == 12) {
        this.spouse.birthdate = getBirthdate(newNric);
      }
    },
  },
  async mounted() {
    let tempProfile = this.theProfile;
    if (!tempProfile.user_raw_profile) {
      tempProfile.user_raw_profile = this.profile.user_raw_profile;
    }
    if (!tempProfile.user_raw_spouses) {
      tempProfile.user_raw_spouses = this.profile.user_raw_spouses;
    }
    this.profile = tempProfile;

    this.init();
  },
});
