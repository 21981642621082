
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";

import { defaultOther } from "../../../utils/ProfileUtils";
import { otherValidator } from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  props: ["theOthers", "states", "oCities"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    const others = [];

    return {
      other: { ...defaultOther},
      others,
      addOtherValidator: otherValidator,
      action: "addOther",
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    const addOtherModal = ref(null);
    const closeModal = () => Modal.getInstance(addOtherModal.value)?.hide();

    return {
      addOtherModal,
      closeModal,
    };
  },
  methods: {
    init() {
      this.others = this.theOthers;
    },
    onStateChange() {
      this.$emit("getCities", { state: this.other.state, type: "others" });
    },
    onShowAddOtherModal() {
      this.action = "addOther";
      this.other = { ...defaultOther};
    },
    onUpdateOthers() {
      if (this.action == "addOther") {
        let tempOther = this.other;
        this.others.push(tempOther);
      }
      this.other = { ...defaultOther};

      this.closeModal();
    },
    onEditOther(index) {
      this.action = "editOther";
      this.other = this.others[index];
    },
    onRemoveOther(index) {
      this.others.splice(index, 1);
    },
    onResetOthers() {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this others details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          for (let index = 0; index < this.others.length; index++) {
            this.others.splice(index, this.others.length);
          }
          Swal.fire({
            title: "Reset!",
            text: "Others details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
    onSaveOthers() {
      this.$emit("onSaveWillPlanning", "Others");
    },
  },
  async mounted() {
    this.init();
  },
});
