
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter, useRoute } from "vue-router";
import {
  defaultProfile,
  defaultParent,
  defaultExecutors,
  defaultTrustees,
} from "../../../utils/ProfileUtils";

import PersonalInformation from "./PersonalInformation.vue";
import SpouseInformation from "./SpouseInformation.vue";
import ChildrenInformation from "./ChildrenInformation.vue";
import ParentsInformation from "./ParentsInformation.vue";
import SiblingsInformation from "./SiblingsInformation.vue";
import OthersInformation from "./OthersInformation.vue";
import ExecutorsInformation from "./ExecutorsInformation.vue";
import DistributionInformation from "./DistributionInformation.vue";
import TrusteesInformation from "./TrusteesInformation.vue";
import PersonalBelongingsInformation from "./PersonalBelongingsInformation.vue";
import SpecialRequestInformation from "./SpecialRequestInformation.vue";
import ResiduaryEstateInformation from "./ResiduaryEstateInformation.vue";
import WillPlanningSummaryModal from "./WillPlanningSummaryModal.vue";

export default defineComponent({
  name: "calculators",
  components: {
    Field,
    PersonalInformation,
    SpouseInformation,
    ChildrenInformation,
    ParentsInformation,
    SiblingsInformation,
    OthersInformation,
    ExecutorsInformation,
    DistributionInformation,
    TrusteesInformation,
    SpecialRequestInformation,
    PersonalBelongingsInformation,
    ResiduaryEstateInformation,
    WillPlanningSummaryModal,
  },
  setup() {
    onMounted(() => {
      // setCurrentPageBreadcrumbs("Will Planning", ["Financial Planning"]);
    });

    const { n } = useI18n();

    return {
      n,
    };
  },
  data() {
    const customerNRICToSearch = "";
    const willPlannings = [];
    const willPlanning = {};
    const showWillPlanning = false;
    const showWillPlanningSummaryModal = false;
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    const isEstateAvailable = true;
    const isMuslim = false;

    const profile = { ...defaultProfile};

    const children = [];
    const spouses = [];

    const siblings = [];
    const others = [];
    const executors = { ...defaultExecutors };
    const trustees = { ...defaultTrustees };

    const special_request = {
      is_enable: false,
      details: "",
    };

    const personal_belongings = {
      details: "",
    };

    const residuary_estate = {
      details: [],
    };

    const activeName = ref("1");

    //Lookup
    const genders = [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
    ];

    const states = [];
    const races = [];
    const faCities = [];
    const moCities = [];
    const f1Cities = [];
    const s2Cities = [];
    const pCities = [];
    const sCities = [];
    const oCities = [];
    const t1Cities = [];
    const t2Cities = [];
    const spCities = [];
    const distribution = {};

    return {
      store: useStore(),
      cstmr_id,
      customerNRICToSearch,
      showWillPlanning,
      showWillPlanningSummaryModal,
      willPlannings,
      willPlanning,
      active: 1,
      activeName,
      profile,
      children,
      genders,
      states,
      spouses,
      races,
      parents: {
        father: { ...defaultParent },
        mother: { ...defaultParent },
      },
      pCities,
      faCities,
      moCities,
      sCities,
      siblings,
      oCities,
      f1Cities,
      s2Cities,
      t1Cities,
      t2Cities,
      spCities,
      others,
      executors,
      isEstateAvailable,
      trustees,
      special_request,
      personal_belongings,
      residuary_estate,
      distribution,
      isMuslim,
    };
  },
  methods: {
    init() {
      if (!this.cstmr_id) {
        // this.searchCustomerByNRIC();
        this.showSearchCustomerModal();
      } else this.getWillPlanning(this.cstmr_id);

      this.store.dispatch(Actions.GET_STATES).then(() => {
        this.states = this.store.getters.getStatesData;
      });

      this.store.dispatch(Actions.GET_RACES).then(() => {
        this.races = this.store.getters.getRacesData;
      });
    },
    handleChange(val) {
      // console.log(val);
    },
    showSearchCustomerModal() {
      new Modal("#searchCustomerModal", {
        backdrop: "static",
        keyboard: false,
      }).show();
    },
    searchCustomerByNRIC() {
      this.showSwalLoading("Fetching Customer Data");
      this.store
        .dispatch(Actions.GET_CUSTOMER_BY_NRIC, this.customerNRICToSearch)
        .then(() => {
          let theProfile = this.store.getters.getCustomerData;
          if (theProfile.id) {
            this.cstmr_id = theProfile.id;
            setTimeout(() => {
              this.redirecting();
            }, 1000);
          } else {
            this.showSwalResult("Customer not found", "error");
          }
        });
    },
    redirecting() {
      this.showSwalLoading("Redirecting");
      setTimeout(() => {
        this.$router
          .push({
            path:
              "/customers/details/" +
              this.cstmr_id +
              "/financial-planning/will-planning",
          })
          .then(() => {
            window.location.reload();
          });
      }, 1000);
    },
    getWillPlanning(user_id) {
      this.showSwalLoading("Populating Data");
      this.store.dispatch(Actions.GET_WILL_PLANNINGS, user_id).then(() => {
        this.willPlannings = this.store.getters.getWillPlanningsData;
        // if(this.willPlannings.length > 0) {

        // } else {
        //   this.showSwalResult("Customer not found")
        // }
        this.getUserProfile(user_id);
      });
    },
    getUserProfile(user_id) {
      this.store.dispatch(Actions.GET_USER_BASIC_PROFILE, user_id).then(() => {
        this.profile = this.store.getters.getCustomerBasicProfileData;
        this.isMuslim = this.profile.user_raw_profile?.raw?.is_muslim ?? false;
        setTimeout(() => {
          this.getFamilyProfile();
        }, 1000);
      });
    },
    getFamilyProfile() {
      this.store
        .dispatch(Actions.GET_USER_FAMILY_PROFILE, this.profile.id)
        .then(() => {
          const family = this.store.getters.getFamilyProfileData
            .user_raw_family;
          // console.log(family);
          if (family !== null) {
            this.spouses = family.raw.spouses;
            this.children = family.raw.children;
            this.parents = family.raw.parents;
            this.siblings = family.raw.siblings;
            this.others = family.raw.others;
          }
          setTimeout(() => {
            this.getWillProfile();
          }, 500);
        });
    },
    getWillProfile() {
      this.store
        .dispatch(Actions.GET_USER_WILL_PROFILE, this.profile.id)
        .then(() => {
          const will = this.store.getters.getWillProfileData.user_raw_will;
          if (will !== null) {
            this.executors = will.raw.executors;
            this.trustees = will.raw.trustees;
            this.distribution = will.raw.distribution;
            this.special_request = will.raw.special_request;
            this.personal_belongings = will.raw.personal_belongings;
            this.residuary_estate = will.raw.residuary_estate.details
              ? will.raw.residuary_estate
              : { details: [] };
          }
          setTimeout(() => {
            this.showWillPlanning = true;
            this.showWillPlanningSummaryModal = true;
            Swal.close();
          }, 500);
        });
    },
    getCities(data) {
      this.store
        .dispatch(Actions.GET_CITIES, this.getStateId(data.state))
        .then(() => {
          const typeToCitiesMap = {
            spouse: "spCities",
            father: "faCities",
            mother: "moCities",
            personal: "pCities",
            siblings: "sCities",
            others: "oCities",
            executor_1: "f1Cities",
            executor_2: "s2Cities",
            trustee_1: "t1Cities",
            trustee_2: "t2Cities",
          };

          const property = typeToCitiesMap[data.type];
          if (property) {
            this[property] = this.store.getters.getCitiesData;
          }
        });
    },
    getStateId(state_name) {
      for (let index of Object.keys(this.states)) {
        if (state_name == this.states[index].ParameterValue) {
          return this.states[index].id;
        }
      }
    },
    showSwalLoading(message) {
      Swal.fire({
        title: message,
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    },
    showSwalResult(message, icon = "success") {
      Swal.fire({
        icon: icon,
        title: message,
        text: "Do you want to proceed?",
        allowOutsideClick: false,
      }).then((result) => {
        if (message == "Customer not found") {
          this.showSearchCustomerModal();
        }
      });
    },
    onSaveWillPlanning(type, data = null) {
      this.isMuslim = this.profile.user_raw_profile.raw.is_muslim ?? false;
      this.showWillPlanningSummaryModal = false;

      Swal.fire({
        title: "Saving " + type + " Information",
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      setTimeout(() => {
        switch (type) {
          case "Personal":
            this.saveProfile(type);
            break;
          case "Spouses":
            this.spouses = this.profile.user_raw_spouses.raw;
            this.saveProfile(type);
            break;
          case "Children":
          case "Parents":
          case "Siblings":
          case "Others":
            this.saveRaw(type, "family");
            break;
          case "Distribution":
            this.saveDistribution(data);
            break;
          case "Executors":
          case "Trustees":
          case "Special Request":
          case "Personal Belongings":
          case "Residuary Estate":
            this.saveRaw(type, "will");
            break;
        }
      }, 1500);
    },
    async saveDistribution(data) {
      this.distribution = data;
      await this.saveProfile("Spouses", false);
      await this.saveRaw("Distribution", "family", false);
      await this.saveRaw("Distribution", "will");
    },
    saveProfile(type, showResult = true) {
      console.log("saveProfile", type);
      this.store.dispatch(Actions.UPDATE_PROFILE, this.profile).then(() => {
        if (type == "Spouses" && showResult) {
          this.saveRaw(type, "family");
        } else {
          if (showResult) {
            Swal.fire({
              icon: "success",
              title: "Personal Information has been saved",
              allowOutsideClick: false,
            });
          }
          this.showWillPlanningSummaryModal = true;
        }
      });
    },
    saveRaw(saved, type, showResult = true) {
      console.log("saveProfile", type);

      let raw = {};

      if (type == "family") {
        raw = {
          spouses: this.spouses,
          children: this.children,
          parents: this.parents,
          siblings: this.siblings,
          others: this.others,
        };
      }
      if (type == "will") {
        raw = {
          executors: this.executors,
          trustees: this.trustees,
          distribution: this.distribution,
          special_request: this.special_request,
          personal_belongings: this.personal_belongings,
          residuary_estate: this.residuary_estate,
        };
      }

      let data = {
        type: type,
        user_id: this.profile.id,
        raw: raw,
      };

      this.store.dispatch(Actions.STORE_RAW, data).then(() => {
        if (showResult) {
          this.showSwalResult("Your " + saved + " Information has been saved");
          this.showWillPlanningSummaryModal = true;
        }
      });
    },
    handleResetExecutors() {
      // Update the parent's data with the new data from the child
      this.executors = { ...defaultExecutors };
      console.log(this.executors);
    },
    submitWillPlanning(action) {
      if (action == "preview") {
        this.showSwalLoading("Summarizing Will Planning");

        setTimeout(() => {
          Swal.close();
          new Modal("#WillPlanningSummaryModal", {
            backdrop: "static",
            keyboard: false,
          }).show();
        }, 1500);
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, submit it!",
          cancelButtonText: "No, cancel!",
          customClass: {
            confirmButton: "btn btn-primary btn-active-primary",
            cancelButton: "btn btn-danger btn-active-primary ms-2",
          },
        }).then((result) => {
          this.showSwalLoading("Submitting Will Planning");

          setTimeout(() => {
            Swal.close();

            const data = {
              user_id: this.profile.id,
              type: "will",
              raw: {
                profile: this.profile,
                spouses: this.spouses,
                children: this.children,
                parents: this.parents,
                siblings: this.siblings,
                others: this.others,
                executors: this.executors,
                trustees: this.trustees,
                distribution: this.distribution,
                special_request: this.special_request,
                personal_belongings: this.personal_belongings,
                residuary_estate: this.residuary_estate,
              },
            };

            this.store.dispatch(Actions.STORE_WILL_PLANNING, data).then(() => {
              this.showSwalResult("Will Planning has been submitted");
            });
          }, 1500);
        });
      }
    },
    getRouteUrl(route) {
      return this.$router.resolve(route).href;
    },
    setEntireEstate(value) {
      this.isEstateAvailable = value;
    },
  },
  async mounted() {
    this.init();
  },
});
